import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import senitize from 'rehype-sanitize';

import { TFontSize } from '../../core/models';
import './Title.css';

interface IProps {
  label?: string;
  title: string;
  size?: TFontSize;
  desc?: string;
}

function Title({ label, title, size, desc }: IProps) {
  return (
    <section className="title section">
      <div className="wrap">
        <div className="flex gap-md">
          {!label ? null : <div className="title__label h3">{label}</div>}
          <h2 className={`title__title ${size || 'h2'}`}>
            <ReactMarkdown
              className="markdown"
              components={{
                em: 'span',
              }}
            >
              {title}
            </ReactMarkdown>
          </h2>
          {!desc ? null : (
            <ReactMarkdown
              className="title__desc markdown"
              rehypePlugins={[rehypeRaw, senitize]}
              components={{
                p: React.Fragment,
              }}
            >
              {desc}
            </ReactMarkdown>
          )}
        </div>
      </div>
    </section>
  );
}

export default Title;
