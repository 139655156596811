import React, { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomLink from '../../common/CustomLink/CustomLink';
import { ILink } from '../../core/models';
import Item from './Item/Item';
import './Support.css';

interface IProps {
  title: string;
  titleTag?: 'h1' | 'h2';
  subtitle?: string;
  desc?: string;
  cta: ILink;
  sections: {
    title: string;
    items: {
      question: string;
      answer: string;
    }[];
  }[];
}

function Support({ title, titleTag, subtitle, desc, cta, sections }: IProps) {
  const [openStr, setOpenStr] = useState<string | null>(null);

  const handleToggle = useCallback((str: string) => {
    setOpenStr((prevState) => (prevState === str ? null : str));
  }, []);

  return (
    <section className="support section">
      <div className="wrap wrap--sm">
        {titleTag === 'h1' ? (
          <h1 className="support__title h1">{title}</h1>
        ) : (
          <h2 className="support__title h1">{title}</h2>
        )}
        <div className="support__contact section-container">
          {!subtitle ? null : <h2 className="support__subtitle h3">{subtitle}</h2>}
          {!desc ? null : (
            <div className="support__desc">
              <ReactMarkdown
                className="markdown"
                components={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  p: React.Fragment as any,
                }}
              >
                {desc}
              </ReactMarkdown>
            </div>
          )}
          {!cta.href ? null : <CustomLink className="support__cta" link={cta} />}
        </div>
        {!sections?.length
          ? null
          : sections.map((section) => (
              <div key={section.title} className="support__section">
                <h2 className="support__section-title h3">{section.title}</h2>
                <div className="support__items">
                  {section.items.map((item, i) => (
                    <Item
                      item={item}
                      isOpen={openStr === `${section.title}${i}`}
                      str={`${section.title}${i}`}
                      handleToggle={handleToggle}
                      key={item.question}
                    />
                  ))}
                </div>
              </div>
            ))}
      </div>
    </section>
  );
}

export default Support;
