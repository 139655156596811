import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { A11y, Mousewheel, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import { Swiper, SwiperSlide } from 'swiper/react';

import CustomButton from '../../common/CustomButton/CustomButton';
import { Icon } from '../../common/Icon/Icon';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { slugify } from '../../core/helpers';
import './AICarousel.css';
import AICarouselItem, { IAICarouselItem } from './AICarouselItem/AICarouselItem';

interface IProps {
  title?: string;
  items: IAICarouselItem[];
  slug: string;
}

function AICarousel({ title, items, slug }: IProps) {
  const { isMobile, width } = useScreenSizeContext();
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const titleRef = useRef<HTMLHeadingElement[] | null[]>([]);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [init, setInit] = useState(false);
  const [highestTitle, setHighestTitle] = useState(null);

  useEffect(() => {
    if (!titleRef.current.length) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const heights: any = titleRef.current.map((el) => el?.scrollHeight);
    heights.max = function () {
      return Math.max.apply(null, this);
    };
    setHighestTitle(heights.max());
  }, [width]);

  const slides = useMemo(
    () =>
      items?.map((item, i) => (
        <SwiperSlide className="ai-carousel__slide" key={item.title}>
          <AICarouselItem
            title={item.title}
            desc={item.desc}
            img={item.img}
            mindstudio={item.mindstudio}
            openai={item.openai}
            anthropicai={item.anthropicai}
            ref={(el) => {
              titleRef.current[i] = el;
            }}
            highestTitle={highestTitle}
          />
        </SwiperSlide>
      )),
    [items, highestTitle],
  );

  return (
    <section className="ai-carousel section">
      <div className="wrap">
        <div className="ai-carousel__header gap-md">
          {!title ? null : (
            <h2 className="ai-carousel__title h2">
              {' '}
              <ReactMarkdown
                className="markdown"
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h2>
          )}
          <div className="ai-carousel__nav slider__nav" hidden={isLocked}>
            <CustomButton
              className="slider__arrow slider__arrow--prev"
              id={`${slug}-${slugify(title || '')}-prev`}
              type="button"
              ref={prevRef}
            >
              <span className="sr-only">Previous slide</span>
              <Icon.Chevron className="icon" />
            </CustomButton>
            <CustomButton
              className="slider__arrow slider__arrow--next"
              id={`${slug}-${slugify(title || '')}-next`}
              type="button"
              ref={nextRef}
            >
              <span className="sr-only">Next slide</span>
              <Icon.Chevron className="icon" />
            </CustomButton>
          </div>
        </div>
        <Swiper
          className={`ai-carousel__slider${isLocked ? ' locked' : ''}`}
          slidesPerView="auto"
          watchOverflow
          modules={[Navigation, Mousewheel, A11y]}
          grabCursor
          mousewheel={{
            forceToAxis: true,
          }}
          spaceBetween={isMobile ? 24 : 40}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSwiper={() => setInit(true)}
          onLock={() => setIsLocked(true)}
          onUnlock={() => setIsLocked(false)}
        >
          {slides}
        </Swiper>
      </div>
    </section>
  );
}

export default AICarousel;
