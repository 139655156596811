import React from 'react';
import ReactMarkdown from 'react-markdown';

import Image from '../../common/Image/Image';
import { IImage } from '../../core/models';
import './PageBanner.css';

interface IProps {
  title: string;
  desc?: string;
  img?: IImage;
}

function PageBanner({ title, desc, img }: IProps) {
  return (
    <section className={`page-banner${!img ? ' page-banner--no-image' : ''}`}>
      <div className="wrap">
        <div className="page-banner__content flex gap-md">
          <h1 className="page-banner__title h1">
            <ReactMarkdown
              className="markdown"
              components={{
                p: React.Fragment,
              }}
            >
              {title}
            </ReactMarkdown>
          </h1>
          {!desc ? null : <p className="page-banner__desc h3">{desc}</p>}
        </div>
      </div>
      {!img ? null : (
        <div className="wrap wrap--xl">
          <div className="page-banner__img-container">
            <Image className="cover-img" img={img} size="large" />
          </div>
        </div>
      )}
    </section>
  );
}

export default PageBanner;
