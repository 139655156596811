import { useMemo } from 'react';

import { CustomCheckboxInterface } from '../../core/models/inputs';
import { Icon } from '../Icon/Icon';

interface Props {
  id: string;
  name: string;
  info?: string;
  label?: React.ReactNode | string;
  invalidMessage?: string;
  input: CustomCheckboxInterface;
  className?: string;
}

function CustomCheckbox({ id, name, info, label, invalidMessage, input, className = '' }: Props) {
  const error = useMemo(
    () => (!input.showError ? '' : !input.isValid ? 'invalid' : ''),
    [input.showError, input.isValid],
  );

  return (
    <div
      className={`input input--checkbox${!error ? '' : ' error'}${
        className ? ` ${className}` : ''
      }`}
    >
      {!label ? null : (
        <label className="input__checkbox-label" htmlFor={id}>
          {label}
          <input
            id={id}
            type="checkbox"
            name={name}
            checked={input.checked}
            onChange={input.onChange}
            className="input__checkbox"
            ref={input.ref}
          />
          {!input.checked ? null : <Icon.Check className="input__checkmark-icon icon" />}
          <span className={`input__checkmark${input.checked ? ' checked' : ''}`} />
        </label>
      )}
      {!info ? null : <div className="input__info caption">{info}</div>}
      {!error ? null : (
        <div className="input__error">
          <span>{error === 'invalid' ? invalidMessage : ''}</span>
        </div>
      )}
    </div>
  );
}

export default CustomCheckbox;
