import React, { useCallback } from 'react';

import { CustomCheckboxInterface, CustomInputInterface } from '../../core/models/inputs';

interface Props {
  children: React.ReactNode;
  className?: string;
  onSubmit?(): void;
  inputs: (CustomInputInterface | CustomCheckboxInterface)[];
}

function CustomForm({ children, className, onSubmit, inputs }: Props) {
  const validateForm = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errors: number[] = [];

      inputs.forEach((input) => {
        if (!input.isValid) {
          input.setShowError(true);
          if (!errors.length) input.ref.current?.focus();
          errors.push(1);
        }
      });

      if (errors.length > 0) return;
      if (onSubmit) onSubmit();
    },
    [inputs, onSubmit],
  );

  return (
    <form className={className} onSubmit={validateForm}>
      {children}
    </form>
  );
}

export default CustomForm;
