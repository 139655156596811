import { memo, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { CSSTransition } from 'react-transition-group';

import { Icon } from '../../../common/Icon/Icon';
import LinkRenderer from '../../../common/LinkRenderer/LinkRenderer';

interface IProps {
  item: {
    question: string;
    answer: string;
  };
  isOpen: boolean;
  str: string;
  handleToggle(str: string): void;
}

const Item = memo(({ item, isOpen, str, handleToggle }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.setProperty('--support-item-height', `${ref.current?.scrollHeight}px`);
  }, [isOpen]);

  return (
    <div className={`support__question${isOpen ? ' open' : ''}`} key={item.question}>
      <button className="support__btn h4" type="button" onClick={() => handleToggle(str)}>
        {item.question}
        <Icon.Chevron className={`${isOpen ? 'flip-y' : ''}`} />
      </button>
      <CSSTransition
        nodeRef={ref}
        in={isOpen}
        timeout={3000}
        classNames="support-body"
        unmountOnExit
      >
        <div className="support__body" ref={ref}>
          <ReactMarkdown
            className="support__body-content markdown"
            components={{
              a: LinkRenderer,
            }}
          >
            {item.answer}
          </ReactMarkdown>
        </div>
      </CSSTransition>
    </div>
  );
});

export default Item;
