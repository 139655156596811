import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CustomCheckboxInterface } from '../models/inputs';

export const useFormCheckbox = (
  initialValue: boolean,
  validate: (newValue: boolean) => boolean = () => true,
): CustomCheckboxInterface => {
  const [checked, setChecked] = useState<boolean>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(validate(checked));
  const [showError, setShowError] = useState<boolean>(false);
  const ref = useRef(null);

  useEffect(() => {
    setChecked(initialValue);
    setShowError(false);
    setIsValid(validate(initialValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShowError(false);
      const newValue = e.target.checked;
      setChecked(newValue);
      setIsValid(validate(newValue));
    },
    [validate],
  );

  const input = useMemo(
    () => ({ checked, onChange, isValid, showError, setShowError, setChecked, ref }),
    [checked, isValid, onChange, showError],
  );

  return input;
};
