/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import useAutosizeTextArea from '../../core/hooks/useAutosizeTextArea';
import { CustomInputInterface } from '../../core/models/inputs';

interface Props {
  id: string;
  name: string;
  label?: string;
  max?: number;
  placeholder: string;
  input: CustomInputInterface;
  emptyMessage?: string;
  invalidMessage?: string;
  className?: string;
  rows?: number;
  onKeyDown?: any;
}

function CustomTextarea({
  id,
  name,
  label,
  placeholder,
  input,
  max,
  emptyMessage,
  invalidMessage,
  className,
  rows,
  onKeyDown,
}: Props) {
  useAutosizeTextArea(input.ref.current, input.value);
  const error = useMemo(
    () => (!input.showError ? '' : input.isEmpty ? 'empty' : !input.isValid ? 'invalid' : ''),
    [input.showError, input.isEmpty, input.isValid],
  );

  return (
    <div className={`input${!error ? '' : ' error'}${!className ? '' : ` ${className}`}`}>
      {!label ? null : (
        <div className="input__header">
          <label className="input__label body-label" htmlFor={id}>
            {label}
          </label>
        </div>
      )}
      <div className="input__wrapper">
        <textarea
          id={id}
          name={name}
          value={input.value}
          onChange={input.onChange as any}
          placeholder={placeholder}
          className="input__field"
          maxLength={max}
          ref={input.ref}
          rows={rows}
          onKeyDown={onKeyDown}
        />
      </div>
      {!error ? null : (
        <div className="input__error">
          <span>
            {error === 'empty' ? emptyMessage : error === 'invalid' ? invalidMessage : ''}
          </span>
        </div>
      )}
    </div>
  );
}

export default CustomTextarea;
