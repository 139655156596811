/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';

import countries from '../../../assets/country-list.json';
import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomImageField from '../../../common/CustomImageField/CustomImageField';
import CustomInput from '../../../common/CustomInput/CustomInput';
import CustomPickField from '../../../common/CustomPickField/CustomPickField';
import CustomQuestionField from '../../../common/CustomQuestionField/CustomQuestionField';
import CustomSelect from '../../../common/CustomSelect/CustomSelect';
import CustomTagField from '../../../common/CustomTagField/CustomTagField';
import CustomTextarea from '../../../common/CustomTextarea/CustomTextarea';
import LocationInput from '../../../common/LocationInput/LocationInput';
import ScrollSpy from '../../../common/ScrollSpy/ScrollSpy';
import { useUserContext } from '../../../core/context/user.context';
import { isEmpty } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import { useFormInput } from '../../../core/hooks/useFormInput';
import { CustomInputInterface, IAnswer, Tag } from '../../../core/models';
import './UserEditForm.css';

function UserEditForm() {
  const basicSectionRef = useRef(null);
  const bioSectionRef = useRef(null);
  const workSectionRef = useRef(null);
  const questionsSectionRef = useRef(null);
  const { user, forceUpdate } = useUserContext();
  const { doFetch: updateUser, loading } = useFetch(`${process.env.REACT_APP_API_URL}/user`, true);
  const { doFetch: uploadImage, loading: loadingUpload } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/upload`,
    true,
  );
  const { doFetch: deleteImage, loading: loadingDelete } = useFetch(
    `${process.env.REACT_APP_API_URL}/image/delete/${user.profile_pic?._id}`,
    true,
  );

  const firstName = useFormInput(user?.firstname || '', (value) => !isEmpty(value));
  const lastName = useFormInput(user?.lastname || '', (value) => !isEmpty(value));
  const country = useFormInput(
    countries.find((el: { code: string; name: string }) => el.code === user?.country_code)?.name ||
      '',
  );
  const bio = useFormInput(user?.bio || '');
  const currentLocationInput = useFormInput(
    `${
      user?.current_location?.city
        ? `${
            user?.current_location?.country
              ? `${user.current_location.city}, ${user.current_location.country}`
              : user.current_location.city
          }`
        : user?.current_location?.country
        ? user.current_location.country
        : ''
    }`,
    (value) => !isEmpty(value),
  );
  const title = useFormInput(user?.title || '', (value) => !isEmpty(value));
  // const privateInput = useFormCheckbox(user?.private === true);
  const companyName = useFormInput(user?.company?.name || '', (value) => !isEmpty(value));
  const roles = user?.community_roles || [];
  const classes = user?.member_classes || [];

  const [image, setImage] = useState<File | null | ''>(null);

  const [currentLocation, setCurrentLocation] = useState<any>({
    city: user?.current_location?.city || '',
    country: user?.current_location?.country || '',
    coords: {
      lat: user?.current_location?.coords?.lat || 0,
      lng: user?.current_location?.coords?.lng || 0,
    },
  });

  const [expertises, setExpertises] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);
  const [questions, setQuestions] = useState<IAnswer[]>([]);
  const [questionInputs, setQuestionInputs] = useState<CustomInputInterface[]>([]);

  const [pronouns, setPronouns] = useState(user?.pronouns || '');

  useEffect(() => {
    if (user.expertises) setExpertises(user.expertises.map((el: Tag) => el._id));
    if (user.industries) setIndustries(user.industries.map((el: Tag) => el._id));
    if (user.life_questions) setQuestions(user.life_questions);
    if (user.pronouns) setPronouns(user.pronouns);
    if (user.current_location) setCurrentLocation(user.current_location);
  }, [user]);

  const onSubmit = useCallback(async () => {
    if (image) {
      const formData = new FormData();

      formData.append('title', image.name);
      formData.append('alt', 'profile image');
      formData.append('is_public', 'true');
      formData.append('img', image);

      const res = await uploadImage({
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formData,
      });
      if (!res) return;
    } else if (image === '') {
      const res = await deleteImage({
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!res) return;
    }

    const body: any = {
      bio: bio.value,
      expertises: expertises,
      industries: industries,
      firstname: firstName.value,
      lastname: lastName.value,
      pronouns: pronouns,
      // private: privateInput.checked,
      life_questions: questions,
      title: title.value,
      company: {
        name: companyName.value,
      },
      current_location: !currentLocationInput.value
        ? null
        : {
            city: currentLocation.city,
            country: currentLocation.country,
            coords: {
              lat: currentLocation.coords.lat,
              lng: currentLocation.coords.lng,
            },
          },
    };

    await updateUser({
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    forceUpdate();
  }, [
    bio.value,
    companyName.value,
    currentLocation.city,
    currentLocation.coords.lat,
    currentLocation.coords.lng,
    currentLocation.country,
    currentLocationInput.value,
    deleteImage,
    expertises,
    questions,
    firstName.value,
    forceUpdate,
    image,
    industries,
    lastName.value,
    pronouns,
    title.value,
    updateUser,
    uploadImage,
    user.token,
  ]);

  return (
    <div className="user-edit-form__container">
      <CustomForm
        className="user-edit-form form"
        onSubmit={onSubmit}
        inputs={[
          firstName,
          lastName,
          bio,
          title,
          companyName,
          currentLocationInput,
          ...questionInputs,
        ]}
      >
        <div className="form__section" ref={basicSectionRef}>
          <h2 className="form__title h3">Basic information</h2>
          <CustomImageField image={image} setImage={setImage} thumbnail={user.profile_pic} />
          <div className="form__group">
            <CustomInput
              id="firstName"
              placeholder="First name"
              name="firstName"
              type="text"
              input={firstName}
              label="What is your first name?"
            />
            <CustomInput
              id="lastName"
              placeholder="Last name"
              name="lastName"
              type="text"
              input={lastName}
              label="What is your last name?"
            />
          </div>
          <CustomSelect
            id="pronouns"
            placeholder="Pronouns"
            name="pronouns"
            label="What are your preferred personal pronouns?"
            options={[
              { value: 'She/her/hers', label: 'She/her/hers' },
              { value: 'He/him/his', label: 'He/him/his' },
              { value: 'They/them/theirs', label: 'They/them/theirs' },
              { value: 'Other', label: 'Other' },
            ]}
            onChange={(e) => setPronouns(e.currentTarget.value)}
            value={pronouns}
          />
          <CustomInput
            id="country"
            placeholder="Country"
            name="country"
            type="text"
            input={country}
            disabled
            label="Country"
          />
          <LocationInput
            id="current-location"
            placeholder="Where do you live?"
            name="current-location"
            input={currentLocationInput}
            label="In which major city do you live (or live closest to)?"
            onChange={(location) => setCurrentLocation(location)}
          />
          <CustomTagField label="My roles" tags={roles} />
          <CustomTagField label="My classes" tags={classes} />
        </div>
        <div className="form__section" ref={bioSectionRef}>
          <h2 className="form__title h3">Biography</h2>
          <CustomTextarea
            id="bio"
            placeholder="Your THNK bio"
            name="bio"
            input={bio}
            label="Your THNK bio"
            rows={7}
          />
        </div>
        <div className="form__section" ref={questionsSectionRef}>
          <h2 className="form__title h3">Life questions</h2>
          <CustomQuestionField
            label="Please pick a maximum of three questions to answer"
            name="questions"
            placeholder="Pick question"
            path="/life_questions/published"
            selected={questions}
            setSelected={setQuestions}
            setQuestionInputs={setQuestionInputs}
            max={3}
          />
        </div>
        <div className="form__section" ref={workSectionRef}>
          <h2 className="form__title h3">Work related</h2>
          <CustomInput
            id="title"
            placeholder="Title"
            name="title"
            type="text"
            input={title}
            label="What is your job title?"
          />
          <CustomInput
            id="company-name"
            placeholder="Company name"
            name="company-name"
            type="text"
            input={companyName}
            label="What is the name of your company or initiative?"
          />
          <CustomPickField
            name="expertises"
            label="What are your primary areas of expertise? (max.3)"
            placeholder="Add an expertise"
            path="/expertises/published"
            selected={expertises}
            setSelected={setExpertises}
            max={3}
          />
          <CustomPickField
            name="industries"
            label="Select the primary industries in which you work. (max.3)"
            placeholder="Add an industry"
            path="/industries/published"
            selected={industries}
            setSelected={setIndustries}
            max={3}
          />
        </div>

        {/* <CustomCheckbox
          className="user-edit-form__checkbox"
          id="private"
          label="Private profile"
          name="private"
          input={privateInput}
        /> */}

        <CustomButton
          type="submit"
          className="user-edit-form__submit btn btn--primary"
          loading={loading || loadingUpload || loadingDelete}
        >
          Save changes
        </CustomButton>
      </CustomForm>
      <ScrollSpy
        items={[
          {
            target: basicSectionRef.current,
            label: 'Basic information',
          },
          {
            target: bioSectionRef.current,
            label: 'Biography',
          },
          {
            target: questionsSectionRef.current,
            label: 'Life questions',
          },
          {
            target: workSectionRef.current,
            label: 'Work related',
          },
        ]}
      />
    </div>
  );
}

export default UserEditForm;
