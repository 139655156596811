import { useEffect } from 'react';

import { isEmpty } from '../../../core/helpers';
import { useFormInput } from '../../../core/hooks/useFormInput';
import { CustomInputInterface, IAnswer } from '../../../core/models';
import CustomTextarea from '../../CustomTextarea/CustomTextarea';

interface IProps {
  value: string;
  id: string;
  name: string;
  label: string;
  placeholder: string;
  setQuestionInputs: React.Dispatch<React.SetStateAction<CustomInputInterface[]>>;
  setSelected: React.Dispatch<React.SetStateAction<IAnswer[]>>;
}

function CustomQuestionFieldItem({
  value,
  id,
  name,
  label,
  placeholder,
  setQuestionInputs,
  setSelected,
}: IProps) {
  const input = useFormInput(value || '', (e) => !isEmpty(e));

  useEffect(() => {
    setQuestionInputs((prevState) => [
      ...prevState.filter((el) => el.ref.current !== input.ref.current),
      input,
    ]);
  }, [input, setQuestionInputs]);

  useEffect(() => {
    setSelected((prevState) => [
      ...prevState.filter((el) => el.question !== id),
      {
        question: id,
        answer: input.value,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value]);

  return (
    <CustomTextarea
      id={id}
      name={name}
      label={label}
      placeholder={placeholder}
      input={input}
      emptyMessage="This field is required!"
      rows={3}
    />
  );
}

export default CustomQuestionFieldItem;
