import { memo, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { CSSTransition } from 'react-transition-group';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { Icon } from '../../../common/Icon/Icon';
import LinkRenderer from '../../../common/LinkRenderer/LinkRenderer';
import { slugify } from '../../../core/helpers';

interface IProps {
  item: {
    question: string;
    answer: string;
  };
  isOpen: boolean;
  i: number;
  handleToggle(i: number): void;
}

const FAQItem = memo(({ item, isOpen, i, handleToggle }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.setProperty('--faq-item-height', `${ref.current?.scrollHeight}px`);
  }, [isOpen]);

  return (
    <div className={`faq__question${isOpen ? ' open' : ''}`} key={item.question}>
      <CustomButton
        className="faq__btn h4"
        type="button"
        onClick={() => handleToggle(i)}
        id={`FAQ-${slugify(item.question)}`}
      >
        {item.question}
        <Icon.Chevron className={`${isOpen ? 'flip-y' : ''}`} />
      </CustomButton>
      <CSSTransition nodeRef={ref} in={isOpen} timeout={3000} classNames="faq-body" unmountOnExit>
        <div className="faq__body" ref={ref}>
          <ReactMarkdown
            className="faq__body-content markdown"
            components={{
              a: LinkRenderer,
            }}
          >
            {item.answer}
          </ReactMarkdown>
        </div>
      </CSSTransition>
    </div>
  );
});

export default FAQItem;
