/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react/no-array-index-key */
import { useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { A11y, FreeMode, Mousewheel, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';

import CustomButton from '../../common/CustomButton/CustomButton';
import { Icon } from '../../common/Icon/Icon';
import Image from '../../common/Image/Image';
import LinkRenderer from '../../common/LinkRenderer/LinkRenderer';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { slugify } from '../../core/helpers';
import { IArticle, Tag } from '../../core/models';
import './CasesShowcase.css';

interface IProps {
  title: string;
  casesShowcaseItems: {
    case: IArticle;
    ctaText?: string;
    ctaId?: string;
  }[];
  background?: 'red' | 'blue';
}

function CasesShowcase({ title, casesShowcaseItems, background }: IProps) {
  const { isMobile } = useScreenSizeContext();
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [locked, setLocked] = useState<boolean>(false);

  const slides = useMemo(
    () =>
      casesShowcaseItems?.map(({ case: item, ctaText, ctaId }, i) => {
        const linkId = ctaId
          ? {
              id: ctaId,
            }
          : null;
        return !item?._id ? null : (
          <SwiperSlide
            className="cases-showcase__item py-sm px-sm"
            role="presentation"
            key={item._id}
          >
            <div className="cases-showcase__img-container">
              <Image className="cases-showcase__img cover-img" img={item.img} size="medium" />
            </div>
            <div className="cases-showcase__content flex">
              <h3 className="cases-showcase__item-title h3 truncate l-2">{item.title}</h3>
              {!item.tags.length ? null : (
                <div className="cases-showcase__tags">
                  {item.tags.map((tag: Tag) => (
                    <div className="tag" key={tag.name}>
                      {tag.name}
                    </div>
                  ))}
                </div>
              )}
              {!item.summary ? null : (
                <ReactMarkdown
                  className="cases-showcase__summary markdown body-large truncate"
                  components={{
                    a: LinkRenderer,
                  }}
                >
                  {item.summary}
                </ReactMarkdown>
              )}
              <Link
                className="cases-showcase__cta btn btn--secondary"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...linkId}
                to={`/case/${item.slug}`}
              >
                {ctaText || 'More about this case'}
                <Icon.Chevron className="icon rotate-minus-90" />
              </Link>
            </div>
          </SwiperSlide>
        );
      }),
    [casesShowcaseItems],
  );

  return (
    <section
      className={`cases-showcase section-bg${
        background === 'red' ? ' section-bg--secondary' : ' section-bg--primary'
      }`}
    >
      <div className="wrap">
        <div className="cases-showcase__header">
          <h2 className="h2">{title}</h2>
          <div className="cases-showcase__nav slider__nav" hidden={locked}>
            <CustomButton
              className="slider__arrow slider__arrow--prev"
              id={`${slugify(title)}-prev`}
              type="button"
              ref={prevRef}
            >
              <span className="sr-only">Previous slide</span>
              <Icon.Chevron className="icon" />
            </CustomButton>
            <CustomButton
              className="slider__arrow slider__arrow--next"
              id={`${slugify(title)}-next`}
              type="button"
              ref={nextRef}
            >
              <span className="sr-only">Next slide</span>
              <Icon.Chevron className="icon" />
            </CustomButton>
          </div>
        </div>
        <div className="cases-showcase__container">
          <Swiper
            className="cases-showcase__slider"
            slidesPerView="auto"
            watchOverflow
            freeMode
            modules={[FreeMode, Navigation, Mousewheel, A11y]}
            grabCursor
            mousewheel={{
              forceToAxis: true,
            }}
            spaceBetween={isMobile ? 24 : 40}
            onSwiper={setSwiperRef}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onLock={() => setLocked(true)}
            onUnlock={() => setLocked(false)}
          >
            {slides}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default CasesShowcase;
