import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';

import mediaGrey from '../../assets/contact-grey.mp4';
import media from '../../assets/contact.mp4';
import Image from '../../common/Image/Image';
import { IContact } from '../../core/models';
import HubSpotFromModal from '../HubSpotFromModal/HubSpotFromModal';
import './Contact.css';

function Contact({
  title,
  desc,
  btnLabel,
  portalId,
  formId,
  region,
  id,
  background,
  slug,
  hideImg,
  btnColor,
  img,
}: IContact) {
  return (
    <section
      className={`contact${
        hideImg ? '' : background === 'grey' ? ' section-bg section-bg--grey' : ' section'
      }${hideImg ? '' : !img ? '' : ' contact--img'}${
        hideImg
          ? ` contact--no-img section-bg section-bg--${
              background === 'grey' ? 'grey' : background === 'white' ? 'white' : 'primary'
            }`
          : ''
      }`}
    >
      <div className="wrap">
        <div className="contact__container px-md py-md gap-lg">
          {hideImg ? null : img ? (
            <div className="content__img-container">
              <Image img={img} className="cover-img" size="medium" />
            </div>
          ) : (
            <div className="contact__media-container">
              <video
                className="contact__media"
                src={background === 'grey' ? media : mediaGrey}
                muted
                autoPlay
                aria-hidden
                playsInline
              />
            </div>
          )}
          <div className={`contact__content flex${hideImg ? ' gap-md' : ' gap-lg'}`}>
            <h2 className="contact__title h2">{title}</h2>
            {!desc ? null : (
              <ReactMarkdown
                className="contact__desc markdown body-large"
                rehypePlugins={[
                  [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                ]}
              >
                {desc}
              </ReactMarkdown>
            )}
            <HubSpotFromModal
              form={{
                btnLabel,
                portalId,
                formId,
                region,
                id,
                btnColor,
              }}
              slug={slug}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
