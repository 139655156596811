/* eslint-disable import/no-named-default */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { Icon } from '../../../common/Icon/Icon';
import Image from '../../../common/Image/Image';
import { useScreenSizeContext } from '../../../core/context/screenSize.context';
import { slugify } from '../../../core/helpers';
import useFeedback from '../../../core/hooks/useFeedback';
import useModal from '../../../core/hooks/useModal';
import { IImage } from '../../../core/models';
import { default as AnthropicChat, IAnthropicAI } from '../../AnthropicAI/Chat/Chat';
import { IMindstudio } from '../../Mindstudio/Mindstudio';
import EmbeddedAI from '../../Mindstudio/QuestionCoach';
import Chat, { IOpenAI } from '../../OpenAI/Chat/Chat';
import './AICarouselItem.css';

export interface IAICarouselItem {
  title?: string;
  desc?: string;
  img?: IImage;
  mindstudio?: IMindstudio;
  openai?: IOpenAI;
  anthropicai?: IAnthropicAI;
  highestTitle: number | null;
}

const AICarouselItem = forwardRef<HTMLHeadingElement, IAICarouselItem>(
  ({ title, desc, img, mindstudio, openai, anthropicai, highestTitle }: IAICarouselItem, ref) => {
    const btnRef = useRef<HTMLButtonElement>(null);
    const descRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLHeadingElement>(null);
    const { width } = useScreenSizeContext();
    const {
      closeModal,
      startTimer,
      feedbackCookie,
      Modal: FeedbackModal,
    } = useFeedback({ ai: anthropicai || openai, btnRef });
    const closeCallback = useMemo(
      () => ({
        closeCallback: closeModal,
      }),
      [closeModal],
    );
    const { modal, open, close } = useModal(closeCallback);

    const openModal = useCallback(() => {
      if (!btnRef.current) return;
      open(btnRef.current);
      if (!feedbackCookie) startTimer();
    }, [feedbackCookie, open, startTimer]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => titleRef.current!, []);

    useEffect(() => {
      if (!descRef.current || !titleRef.current) return;
      const titleHeight = titleRef.current.scrollHeight;
      const heightDif = (highestTitle || 0) - titleHeight;
      descRef.current.style.marginTop = `${heightDif}px`;
    }, [highestTitle, width]);

    return (
      <div className="flex gap-sm">
        <div className="ai-carousel__slide-img-container">
          {!img ? null : (
            <Image className="ai-carousel__slide-img cover-img" img={img} size="small" />
          )}
        </div>
        <div className="ai-carousel__slide-content flex gap-sm">
          {!title ? null : (
            <h3 className="h3" ref={titleRef}>
              {' '}
              <ReactMarkdown
                className="markdown"
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h3>
          )}
          {!desc ? null : (
            <div ref={descRef}>
              <ReactMarkdown className="markdown">{desc}</ReactMarkdown>
            </div>
          )}
          {!openai && !mindstudio && !anthropicai ? null : (
            <CustomButton
              className="btn btn--secondary"
              onClick={openModal}
              type="button"
              ref={btnRef}
              id={openai?.btnId || mindstudio?.btnId || anthropicai?.btnId}
            >
              {openai?.btnLabel || mindstudio?.btnLabel || anthropicai?.btnLabel || 'Open'}
            </CustomButton>
          )}
        </div>
        {!openai
          ? null
          : modal({
              side: 'right',
              keepMounted: true,
              children: (
                <>
                  <CustomButton
                    className="modal__close"
                    onClick={close}
                    type="button"
                    id={`${slugify(title || '')}-modal-close`}
                  >
                    <Icon.Plus className="icon-large" />
                    <span className="sr-only">Close modal</span>
                  </CustomButton>
                  {!openai?.assistantID ? null : <Chat ai={openai} />}
                </>
              ),
            })}
        {!mindstudio
          ? null
          : modal({
              children: (
                <>
                  <CustomButton
                    className="modal__close"
                    onClick={close}
                    type="button"
                    id={`${title ? slugify(title) : 'ai-item'}-modal-close`}
                  >
                    <Icon.Plus className="icon-large" />
                    <span className="sr-only">Close modal</span>
                  </CustomButton>
                  {!mindstudio?.appId || !mindstudio.publicToken ? null : (
                    <div className="ai-carousel__chat-container">
                      <EmbeddedAI publicToken={mindstudio.publicToken} appId={mindstudio.appId} />
                    </div>
                  )}
                </>
              ),
            })}
        {!anthropicai
          ? null
          : modal({
              side: 'right',
              keepMounted: true,
              children: (
                <>
                  <CustomButton
                    className="modal__close"
                    onClick={close}
                    type="button"
                    id={`${slugify(anthropicai?.title || '')}-modal-close`}
                  >
                    <Icon.Plus className="icon-large" />
                    <span className="sr-only">Close modal</span>
                  </CustomButton>
                  {!anthropicai ? null : <AnthropicChat ai={anthropicai} />}
                </>
              ),
            })}
        <FeedbackModal />
      </div>
    );
  },
);

export default AICarouselItem;
