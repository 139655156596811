/* eslint-disable jsx-a11y/anchor-is-valid */
import { createElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import { ReactComponent as CorpLogo } from '../../assets/B-Corp-Logo-small.svg';
import { ReactComponent as ISO } from '../../assets/ISO.svg';
import { ReactComponent as Logo } from '../../assets/logo-full-light.svg';
import { Icon } from '../../common/Icon/Icon';
import { useDataContext } from '../../core/context/data.context';
import { mailTo } from '../../core/helpers';
import { IFooter } from '../../core/models';
import NewsLetterInline from '../NewsLetterInline/NewsLetterInline';
import './Footer.css';

function Footer() {
  const {
    data: {
      generalData: { footer },
    },
  }: { data: { generalData: IFooter } } = useDataContext();

  if (!footer) return null;

  return (
    <footer className="footer">
      <div className="wrap">
        <div className="footer__container flex gap-lg">
          <div className="footer__top gap-md">
            <div className="footer__left flex gap-md">
              <Link to="/" id="footer-logo">
                <span className="sr-only">Home page</span>
                <Logo className="footer__logo" />
              </Link>
              <div className="footer__info">
                <address className="footer__address">
                  <a
                    className="footer__address-link"
                    id="footer-address-link"
                    href={footer.addressHref}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ReactMarkdown className="markdown">{footer.address}</ReactMarkdown>
                  </a>
                  <br />
                </address>
                <a
                  className="footer__mail"
                  id="footer-mail"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    mailTo(footer.email);
                  }}
                  aria-label={`Send a mail to ${footer.email}`}
                >
                  {footer.email}
                </a>
              </div>
            </div>
            <div className="footer__right flex gap-md">
              <div className="footer__right-header">
                <a
                  className="footer__corp"
                  id="footer-corp"
                  href={footer.bCorpURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">B Corp</span>
                  <CorpLogo className="footer__corp-icon" />
                </a>
                <ISO className="footer__iso" />
              </div>
              <NewsLetterInline
                portalId={footer.newsletter?.portalId}
                formId={footer.newsletter?.formId}
                region={footer.newsletter?.region}
                id={footer.newsletter?.id}
              />
            </div>
          </div>
          <div className="footer__bottom">
            <nav className="footer__bottom-nav gap-md">
              <ul className="footer__social gap-sm">
                {footer.social?.map((el, i) => (
                  <li key={el.label}>
                    <a
                      className="footer__social-link"
                      href={el.href}
                      target="_blank"
                      rel="noreferrer"
                      id={`footer-social-link-${i + 1}`}
                    >
                      {createElement(Icon[el.icon], { className: 'icon-xl' })}
                      <span className="sr-only">{el.label}</span>
                    </a>
                  </li>
                ))}
              </ul>
              <ul className="footer__bottom-list gap-md">
                {footer.navigation?.map((el) => (
                  <li className="footer__bottom-item" key={el.label}>
                    {el.href ? (
                      <Link className="footer__bottom-link controls-link-small" to={el.href}>
                        {el.label}
                      </Link>
                    ) : (
                      <div className="footer__bottom-text">{el.label}</div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
