import React, { useCallback, useMemo, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomLink from '../../common/CustomLink/CustomLink';
import { Icon } from '../../common/Icon/Icon';
import { slugify } from '../../core/helpers';
import useFeedback from '../../core/hooks/useFeedback';
import useModal from '../../core/hooks/useModal';
import { ILink } from '../../core/models';
import './AnthropicAI.css';
import Chat, { IAnthropicAI } from './Chat/Chat';

interface IProps {
  title: string;
  desc?: string;
  cta?: ILink;
  anthropicai?: IAnthropicAI;
  background?: 'red' | 'white' | 'dark-blue' | 'light-blue';
}

function AnthropicAI({ title, desc, cta, anthropicai, background }: IProps) {
  const btnRef = useRef(null);
  const {
    closeModal,
    startTimer,
    feedbackCookie,
    Modal: FeedbackModal,
  } = useFeedback({ ai: anthropicai, btnRef });
  const closeCallback = useMemo(
    () => ({
      closeCallback: closeModal,
    }),
    [closeModal],
  );
  const { modal, open, close } = useModal(closeCallback);
  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
    if (!feedbackCookie) startTimer();
  }, [feedbackCookie, open, startTimer]);

  return (
    <section
      className={`anthropic section-bg${
        !background || background === 'red'
          ? ' section-bg--secondary'
          : background === 'white'
          ? ' section-bg--white'
          : background === 'dark-blue'
          ? ' section-bg--dark'
          : background === 'light-blue'
          ? ' section-bg--primary'
          : ''
      }`}
    >
      <div className="wrap">
        <div className="anthropic__container gap-lg">
          <div className="anthropic__left">
            <h2 className="anthropic__title h2">
              <ReactMarkdown
                className="markdown"
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h2>
          </div>
          <div className="anthropic__right gap-md">
            {!desc ? null : (
              <ReactMarkdown className="anthropic__desc markdown h3">{desc}</ReactMarkdown>
            )}
            {!cta ? null : (
              <div className="anthropic__btn-container">
                {!cta ? null : <CustomLink className="anthropic__cta" link={cta} />}
                {!anthropicai ? null : (
                  <CustomButton
                    className={`btn btn--${background === 'light-blue' ? 'secondary' : 'primary'}`}
                    onClick={openModal}
                    type="button"
                    ref={btnRef}
                    id={anthropicai?.btnId}
                  >
                    {anthropicai.btnLabel || 'Open'}
                  </CustomButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!anthropicai
        ? null
        : modal({
            side: 'right',
            keepMounted: true,
            children: (
              <>
                <CustomButton
                  className="modal__close"
                  onClick={close}
                  type="button"
                  id={`${slugify(title)}-modal-close`}
                >
                  <Icon.Plus className="icon-large" />
                  <span className="sr-only">Close modal</span>
                </CustomButton>
                {!anthropicai ? null : <Chat ai={anthropicai} />}
              </>
            ),
          })}
      <FeedbackModal />
    </section>
  );
}

export default AnthropicAI;
