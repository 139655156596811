import { useCallback, useEffect, useMemo, useState } from 'react';

import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';
import { CustomInputInterface, IAnswer, IQuestion } from '../../core/models';
import CustomInput from '../CustomInput/CustomInput';
import { Icon } from '../Icon/Icon';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import './CustomQuestionField.css';
import CustomQuestionFieldItem from './CustomQuestionFieldItem/CustomQuestionFieldItem';

interface IProps {
  name: string;
  label: string;
  placeholder: string;
  path: string;
  selected: IAnswer[];
  setSelected: React.Dispatch<React.SetStateAction<IAnswer[]>>;
  setQuestionInputs: React.Dispatch<React.SetStateAction<CustomInputInterface[]>>;
  max?: number;
}

function CustomQuestionField({
  name,
  label,
  placeholder,
  path,
  selected,
  setSelected,
  setQuestionInputs,
  max,
}: IProps) {
  const search = useFormInput('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { doFetch, result } = useFetch(`${process.env.REACT_APP_API_URL}${path}`);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  useEffect(() => {
    setFilteredItems(result?.data.items || []);
  }, [result?.data]);

  useEffect(() => {
    if (!result?.data.items) return;
    const items = result.data.items.filter((el: IQuestion) =>
      el.question.toLowerCase().includes(search.value.trim()),
    );
    setFilteredItems(items);
  }, [search.value, result?.data.items]);

  const removeItem = useCallback(
    (selectedId: string) => {
      const items = selected.filter((el) => el.question !== selectedId);
      setSelected(items);
    },
    [selected, setSelected],
  );

  const addItem = useCallback(
    (selectedId: IAnswer) => {
      setSelected((prevState) => [...prevState, selectedId]);
      setIsDropdownOpen(false);
    },
    [setSelected],
  );

  const selectedItems = useMemo(() => {
    if (!result?.data || !selected) return null;
    const items = result?.data.items.filter((el: IQuestion) =>
      selected.map((item) => item.question).includes(el._id),
    );
    return items.map((item: IQuestion) => (
      <div className="question-field__selected" key={item._id}>
        <div className="question-field__selected-question body-small">
          {item.question}
          <button
            className="question-field__selected-remove"
            type="button"
            key={item._id}
            onClick={() => removeItem(item._id)}
          >
            <Icon.Bin className="icon" />
            <span className="sr-only">Delete life question</span>
          </button>
        </div>
        <div className="question-field__selected-answer">
          <CustomQuestionFieldItem
            id={item._id}
            name={`question-${item._id}`}
            label="Your answer"
            placeholder="Your answer"
            value={selected.find((el: IAnswer) => el.question === item._id)?.answer || ''}
            setQuestionInputs={setQuestionInputs}
            setSelected={setSelected}
          />
        </div>
      </div>
    ));
  }, [removeItem, result?.data, selected, setQuestionInputs, setSelected]);

  const options = useMemo(() => {
    if (!filteredItems) return null;
    const items = filteredItems.filter(
      (el: IQuestion) => !selected.map((item) => item.question).includes(el._id),
    );
    return items.map((item: IQuestion) => (
      <button
        className="question-field__option body-small"
        type="button"
        key={item._id}
        onClick={() =>
          addItem({
            question: item._id,
            answer: '',
          })
        }
        disabled={max ? selected.length >= max : false}
      >
        {item.question}
      </button>
    ));
  }, [addItem, max, filteredItems, selected]);

  return (
    <div className="question-field">
      <label className="question-field__label body-label" htmlFor={name}>
        {label}
      </label>
      <OutsideAlerter onClick={() => setIsDropdownOpen(false)} detectTab>
        <div className="question-field__container">
          <CustomInput
            id={name}
            placeholder={placeholder}
            name={name}
            type="text"
            input={search}
            icon={<Icon.Search className="icon" />}
            onFocus={() => setIsDropdownOpen(true)}
          />
          <div
            className={`question-field__dropdown${
              isDropdownOpen ? ' question-field__dropdown--visible' : ''
            }`}
          >
            {options}
          </div>
        </div>
      </OutsideAlerter>
      <div className="question-field__answer-container">{selectedItems}</div>
    </div>
  );
}

export default CustomQuestionField;
